.footer-cont{
    text-align: center;
    font-size: 20px;
    height: 100px;
    line-height: 100px;
    background-color: rgb(50, 50, 50);
    color: white;
}

.swd-logo{
    position: absolute;
    height: 100px;
    right: 0;
    margin-right: 10vw;
}

@media screen and (max-width: 700px){
    .swd-logo{
        display: none;
    }
}