/*
@media screen and (min-width: 800px) {
  .vid-cont {
    width: 70%;
    margin-left: 15%;
    margin-top: 50px;
  }
}
@media screen and (min-width: 1000px) {
  .vid-cont {
    width: 60%;
    margin-left: 20%;
    margin-top: 50px;
  }
}

@media screen and (min-width: 1200px) {
  .vid-cont {
    width: 50%;
    margin-left: 25%;
    margin-top: 50px;
  }
}
  */

  .homepage-cont{
    pointer-events: none;
    overflow: hidden;
  }
  canvas {
    position: relative;
    width: 100vw;
    background-color: black;
  }

  @media screen and (min-width: 700px){
    canvas {
      width: 60vw;
      left: 50%;
      transform: translateX(-50%);
      margin-top: 5%;
      margin-bottom: 5%;
      border-radius: 50vw;
    }
  }

  @media screen and (min-width: 800px){
    canvas {
      margin-top: 10%;
      margin-bottom: 10%;
    }
  }

  @media screen and (min-width: 900px){
    .white_ball{
      r: 15;
    }
  }

  @media screen and (min-width: 1000px){
    canvas {
      width: 50vw;
    }
  }

  @media screen and (min-width: 1100px){
    canvas {
      width: 40vw;
    }
  }

    .vid-cont {
      position: relative;
      background-color: black;
    }

    .web-design{
      font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
      position: relative;
      font-size: 12vw;
      height: 500px;
      line-height: 500px;
      width: 100vw;
      font-weight: bold;
      color: white;
    }
    .whiteBall{
      position: absolute;
      top: 0;
      left: 0;
    }
    .white_ball{
      position: absolute;
      fill: blue;
    }

    .simplified-cont{
      position: relative;
      background-color: white;
      height: 1000px;
    }

    .ball_line{
      position: absolute;
    }

    @media screen and (max-width: 500px){
      .white_ball{
        cx: 80%;
        cy: 38.3%;
      }
    }
    @media screen and (max-width: 900px) and (orientation: landscape){
      .white_ball{
        cx: 78%;
        cy: 38.3%;
      }
    }

    @media screen and (min-width: 1300px){
      .vid-cont{
        display: grid;
        grid-template-columns: repeat(2, auto);
      }
      .canvas-cont{
        height: 1000px;
        width: 50vw;
        background-color: black;
        order: 2;
      }

      canvas{
        position: absolute;
        background-color: white;
        margin: 0;
        padding: 0;
        top: 50%;
        transform: translateY(-50%);
        left: 55%;
      }

      .web-design{
        order: 1;
        width: 50vw;
        height: 1000px;
        background-color: black;
      }

      .ball_line{
        transform: scale(0.75);
        overflow: visible;
      }

      .ball{
        position: absolute;
      }

      .whiteBall{
        position: absolute;
        top: 10%;
      }
      .white_ball{
        cx: 85%;
        cy: 38.5%;
      }
      .web_design_text{
        font-size: 6vw;
      }
    }
  
