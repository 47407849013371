header a {
    text-decoration: none;
    color: white;
    font-weight: bold;
  }
  
  header a:hover {
    color: pink;
    text-decoration: none;
    transition: all 0.5s ease;
  }

  .header-cont{
    display: grid;
    grid-template-columns: 20% 60% 20%;
  }

  @media screen and (max-width: 1200px){
    .header-cont{
      grid-template-columns: 15% 70% 15%;
    }
  }

  @media screen and (max-width: 1000px){
    .header-cont{
      grid-template-columns: 10% 80% 10%;
    }
  }

  @media screen and (max-width: 800px){
    .header-cont{
      grid-template-columns: 0% 100% 0%;
    }
  }

  .home, .portfolio{
    background-color: blue;
    line-height: 60px;
    text-align: center;
    font-size: 20px;
  }
.header-routes{
    display: grid;
    grid-template-columns: repeat(2,auto);
    text-align: center;
    width: 100%;
    font-size: 20px;
    height: 60px;
    line-height: 60px;
    background-color: blue;/*rgb(20,56,176);*/
}

.blackout-1{
  height: 60px;
  background-image: linear-gradient(to right, black, blue);
}
.blackout-2{
  background-image: linear-gradient(to right, blue,black);
}