.image-card{
   margin-left: 30%;
   margin-top: 100px;
   margin-bottom: 100px;
   text-align: center;
    width: 40%;
    height: 40%;
    font-size: 30px;
    font-weight: bold;
    font-style: italic;
}

@media screen and (max-width: 1200px){
    .image-card{
        width: 50%;
        height: 50%;
        margin-left: 25%;
    }
}

@media screen and (max-width: 1000px){
    .image-card{
        width: 60%;
        height: 60%;
        margin-left: 20%;
    }
}

@media screen and (max-width: 800px){
    .image-card{
        width: 75%;
        height: 75%;
        margin-left: 12.5%;
        font-size: 3.75vw;
    }
}

@media screen and (max-width: 600px){
    .image-card{
        font-size: 22.5px;
    }
}

.horizontal-line{
    margin-bottom: 30px;
}

.red-image{
    width: 100%;
    height: 100%;
}

.red-image:hover{
    transform: scale(1.05);
    transition: ease-in all 0.1s;
    cursor: pointer;
}
 
.kormos-image{
    width: 100%;
    height: 100%;
}

.kormos-image:hover{
    transform: scale(1.05);
    transition: ease-in all 0.1s;
    cursor: pointer;
}